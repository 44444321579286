// Need to overwrite NC component embedded styling
header {
  position: fixed !important;
}

input.form-control {
  padding: 4px 12px 5px;
  color: var(--input-text-color);
  border-radius: 0;
  border: 1px solid var(--input-border-color);
  background-color: var(--input-bg);
  -webkit-appearance: none;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

// Without it caret overlaps Visma.net logo
#vud-navbar-brand-menu {
  display: flex;
  align-items: center;

  .caret {
    position: static;
  }
}

// Toggle with icon increases menu height
#dropdown-toggle {
  padding-bottom: 0;
}

// Stepper labels don't align with bubbles on the left
.stepper.stepper-vertical.stepper-vertical-sm > ul > li.nav-step > * > span:not(.step) {
  top: -1.5rem;
}

// Carets get cut from the right
.caret {
  -webkit-mask-size: contain !important;
  mask-size: contain !important;
}

// Modal buttons are moved out of the modal element
.modal-body {
  height: auto;
}

// Height shouldn't change for bulk tags add/remove all inputs
.all-tag-input {
  .ng-select-container,
  .ng-value-container {
    max-height: 30px;
  }
}

// Some changes for stepper in the vertical nav
.nav-step {
  span {
    white-space: break-spaces;
  }

  &.active:after {
    display: none !important;
  }
}

// custom HRM styles for search input
.search-group .search-icon::before {
  background-color: var(--neutral-80);
}

#search_input::placeholder {
  color: var(--neutral-80);
}

// Delete after ui-shared-ag-grid update
[class*='ag-theme-'] {
  font-size: 14px !important;
}

.ag-theme-visma {
  .ag-checkbox-input-wrapper::after {
    border: 0 solid;
  }

  input {
    border: var(--ag-borders-input) var(--ag-input-border-color);
  }
}

.ag-theme-visma {
  .visma-warning-row {
    padding-bottom: 10px;
    padding-left: calc(var(--ag-row-group-indent-size) + var(--ag-cell-horizontal-padding));
    background-color: var(--orange-05);
    white-space: pre-wrap;
  }
}

.ag-theme-visma .ag-row:has(.ag-cell-focus)::before,
.ag-theme-visma .ag-row.ag-row-hover::before,
.ag-theme-visma .ag-row:has(.ag-cell.visma-warning-cell)::before,
.ag-theme-visma .ag-row.visma-warning-row::before,
.ag-theme-visma .ag-row:has(.ag-cell.visma-invalid-cell)::before,
.ag-theme-visma .ag-row.visma-invalid-row::before {
  border-left-width: 4px !important;
}

.ag-theme-visma .visma-warning-row::before {
  border-left-color: var(--orange-60);
}

// TODO: check if scoping to component is really needed
di-employees-ic-grid {
  .ag-theme-visma .ag-pinned-left-cols-container .ag-row.ag-row-hover::before {
    border-left-color: var(--blue-50);
  }

  .ag-theme-visma .ag-pinned-left-cols-container .ag-row:has(.ag-cell-focus)::before {
    border-left-color: var(--green-50);
  }

  .ag-theme-visma .ag-pinned-left-cols-container .ag-row:has(.ag-cell.visma-invalid-cell)::before {
    border-left-color: var(--red-70);
  }

  .ag-theme-visma .ag-pinned-left-cols-container .ag-row:has(.ag-cell.visma-warning-cell)::before {
    border-left-color: var(--orange-60);
  }

  .ag-theme-visma .ag-center-cols-container .ag-row.ag-row-hover::before,
  .ag-theme-visma .ag-center-cols-container .ag-row:has(.ag-cell-focus)::before {
    border-left-color: transparent;
  }
}

.ag-status-bar {
  height: 46px;

  .ag-status-bar-right {
    margin-right: -10px;
    gap: 16px;
    align-items: center;

    button {
      margin: 0px;
    }
  }
}

.divider {
  height: 0;
  margin-top: 48px;
  margin-bottom: 12px;
  border-bottom: 1px solid var(--module-container-divider);
}

//need to overwrite z-index for drawer because datepicker is not visible with 1030
.drawer {
  z-index: 1000;
  // VUD drawer has Open Sans h2, in payroll we have Ubuntu
  .drawer-header {
    h2 {
      font-size: 2rem;
      font-family: 'Ubuntu Regular', 'UbuntuFallback', sans-serif;
    }
  }
}

.drawer-wrapper {
  display: flex;
  flex-direction: column;
  max-height: 100vh;
}

// Until ngx vud is updated
vud-datepicker-input.readonly-inverted,
vud-select.readonly-inverted {
  pointer-events: none;

  input,
  input:hover,
  .ng-select-container {
    border-radius: 4px;
    box-shadow: none;
    border: 0;
    background-color: var(--bg-primary);
    color: var(--neutral-90) !important;
  }
}

input[readonly].inverted,
input[readonly]:hover.inverted,
input.form-control[readonly].inverted,
input[readonly]:hover.inverted {
  border-radius: 4px !important;
  box-shadow: none !important;
  border: 0 !important;
  background-color: var(--bg-primary) !important;
  color: var(--neutral-90) !important;
}

// custom styles for payroll badge (VUD label)
.label.payroll-badge {
  box-shadow: none;
  background-color: var(--neutral-20);
  font-weight: 600;
  color: var(--neutral-70);
  font-size: 13px;

  &.label-info {
    background-color: var(--blue-10);
    color: var(--blue-70);
  }
}

.nav-tabs:not(.navbar-nav).nav-tabs-primary .nav-item a {
  padding-bottom: 0rem;
  line-height: 1;
  padding-top: 3rem;
  padding-bottom: 1.6rem;
}

vud-select .ng-select-container {
  border-radius: 0.4rem;
}

label.required-label {
  position: relative;
}

// remove some unnecessary opinionated styles from the drawer
vud-drawer-container {
  width: 100%;
}

.drawer {
  padding-top: 0;
}

.vud-btn.btn-icon .vismaicon {
  margin: 0;
}
