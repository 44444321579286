﻿@media print {
  .nav-space {
    padding-top: 0 !important;

    .hide-on-print {
      display: none !important;
    }

    .navbar,
    .vertical-nav,
    .alert,
    .breadcrumb,
    .initials {
      display: none !important;
    }

    .page-holder {
      padding-left: 0 !important;
    }

    di-employee-card {
      .panel-body,
      .panel-heading {
        padding-top: 0.8rem !important;
        padding-bottom: 0.8rem !important;
      }

      .panel.rounded.mb-32 {
        border: 1px solid var(--neutral-40);
        margin-bottom: 0.8rem !important;
      }

      select,
      vud-select,
      :not(.ng-input) > input {
        border: 1px solid var(--neutral-40) !important;
      }
    }
  }

  .no-print {
    display: none;
  }
}
