@use '@vismaux/ngx-vud';
@use '@angular/cdk/overlay-prebuilt.css';
@use 'assets/fonts/styles';
@use 'assets/styles/icons';
@use 'assets/styles/variables' as vars;
@use 'assets/styles/elements';
@use 'assets/styles/vud-extras';
@use 'assets/styles/tables';
@use 'assets/styles/print_settings';
@use '../../../libs/hrm-ag-grid/src/assests/styles/ag-theme-hrm/ag-theme-hrm';

@tailwind utilities;

html,
body {
  height: 100%;
  padding: 0;
  font-family: 'Open Sans', OpenSansFallback, sans-serif;
}

body {
  &.nav-space {
    padding-top: vars.$h-header;
  }

  &.nav-space-settings {
    padding-top: calc(#{vars.$h-header} - 64px);
  }
}

.loading {
  opacity: 0;
  transform: translate(-50%, -50%);
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  z-index: -1;
}

di-root:empty + .loading {
  opacity: 1;
  z-index: 100;
}
